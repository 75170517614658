import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

const NavbarComponent = () => {
  return (
    <Navbar bg="transparent">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src="https://edhicadmin.org/assets/images/logo-edo.svg"
            width="60"
            height="60"
            className="d-inline-block align-top rounded-circle"
          />{" "}
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
