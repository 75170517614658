import { Footer, Navbar } from "../components";
import one from "../assets/1.png";
import two from "../assets/2.png";
import three from "../assets/3.png";
import "../App.css";

const Landing = () => {
  return (
    <div className="App">
      <Navbar />
      <div className="container projects">
        <div className="card-container">
          <div className="project">
            <div className="card">
              <a href="https://portal.edohis.org/">
                <img src={one} class="card-img-top" alt="..." />{" "}
              </a>
              <div className="card-body">
                <h5 className="card-title">Enrolle Portal</h5>
                <p className="card-text">Checkout more about you.</p>
                <a href="https://portal.edohis.org/" class="btn btn-success">
                  View Site
                </a>
              </div>
            </div>
          </div>
          <div className="project">
            <div className="card">
              <a href="https://app.edohis.org/">
                <img src={two} class="card-img-top" alt="..." />
              </a>
              <div className="card-body">
                <h5 className="card-title">Vulnerability Assessment</h5>
                <p className="card-text">See how vulnerable you are.</p>
                <a href="https://app.edohis.org/" class="btn btn-success">
                  View Site
                </a>
              </div>
            </div>
          </div>
          <div className="project">
            <div className="card">
              <a href="https://app.edohis.org/assessment">
                <img src={three} class="card-img-top" alt="..." />
              </a>
              <div className="card-body">
                <h5 className="card-title">Staff Assessment</h5>
                <p className="card-text">See how well you have performed.</p>
                <a
                  href="https://app.edohis.org/assessment"
                  class="btn btn-success"
                >
                  View Site
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
