import React from "react";

const FooterComponent = () => {
  return (
    <div className="footer container text-center fw-bold">
      <p className="text-secondary">Powered by ICT team EDOHIS</p>
    </div>
  );
};

export default FooterComponent;
