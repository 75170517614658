import "../App.css";
import { Footer } from "../components";
const Login = () => {
  return (
    <div className="login text-center">
      <main className="form-signin w-100 m-auto">
        <form>
          <img
            className="mb-4"
            src="https://edhicadmin.org/assets/images/logo-edo.svg"
            alt=""
            width="72"
            height="57"
          />
          <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <label htmlFor="floatingInput">Email address</label>
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
            />
            <label htmlFor="floatingPassword">Password</label>
          </div>

          <div className="checkbox mb-3">
            <label>
              <input type="checkbox" value="remember-me" /> Remember me
            </label>
          </div>
          <button className="w-100 btn btn-lg btn-success" type="submit">
            Sign in
          </button>
        </form>
        <Footer/>
      </main>
    </div>
  );
};

export default Login;
